import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import Iconify from '../Iconify.tsx'
import { useTheme } from '@mui/material/styles'

export function NewConversationSuggestions() {
  return (
    <Stack direction='row' spacing={3}>
      <Suggestion
        icon={<Iconify color="#e2c541" fontSize={24} icon='ph:currency-circle-dollar' />}
        text='What content is converting?'
      />
      <Suggestion
        icon={<Iconify color="#76d0eb" fontSize={24} icon='ph:lightbulb' />}
        text='What is new?'
      />
      <Suggestion
        icon={<Iconify color="#336699" fontSize={24} icon='ph:users-three' />}
        text="What's revelant for <prospect>?"
      />
      <Suggestion
        icon={<Iconify color="#cb8bd0" fontSize={24} icon='ph:quotes' />}
        text='Find me some quotes for...'
      />
    </Stack>
  )
}

function Suggestion({ icon, text }: { icon: React.ReactNode; text: string; }) {
  const theme = useTheme()
  return (
    <Box
      sx={{
        padding: 2,
        width: 160,
        cursor: 'pointer',
        borderRadius: 1,
        border: `1px solid #ffffff22`,
        textAlign: 'left',
        transition: "background 100ms ease-in-out",
        ':hover': {
          background: theme.palette.background.paper
        }
      }}
    >
      <Box>{icon}</Box>
      <Typography variant='standard' color={"text.deemphasized"}>{text}</Typography>
    </Box>
  )
}