import { Asset, AssetPermission } from '../../../@types/asset'
import { AssetContentState, AssetContentStateStatus } from '../../../@types/asset-content'
import React from 'react'
import { ReadinessContainer } from '../ReadinessContainer'
import { ImageSummaryPopover } from './ImageSummaryPopover.tsx'
import { Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Iconify from '../../Iconify.tsx'
import { useRetryImageSummary } from '../../../hooks/useAssetContent.ts'
import { VisibleForPermission } from '../../../guards/VisibleForPermission.tsx'

export function ImageSummaryReadiness({ asset, state }: { asset: Asset, state: AssetContentState }) {
  const { mutateAsync: retryOCR, isLoading: imageSummaryInProgress } = useRetryImageSummary()
  const handleRetry = () => retryOCR({ assetId: asset.assetId })
  const isReady = state.status === AssetContentStateStatus.SUCCESS
  const buttonsDisabled = imageSummaryInProgress

  return (
    <ReadinessContainer
      icon='eva:image-outline'
      state={state}
      title='Image Summary'
      subtitle='Help users and prospects to understand visual assets at a glance. This also aids in helping individuals to search by what an image is showing!'
    >
      <Stack direction='row' spacing={1}>
        {isReady && (<ImageSummaryPopover asset={asset} />)}

        <VisibleForPermission permission={AssetPermission.EDIT_METADATA} level={asset.accessLevel}>
          <LoadingButton
            disabled={buttonsDisabled}
            variant='text'
            size='small'
            startIcon={<Iconify icon='ph:arrow-clockwise' />}
            loading={imageSummaryInProgress}
            loadingPosition='start'
            onClick={handleRetry}
          >
            Rerun
          </LoadingButton>
        </VisibleForPermission>
      </Stack>

    </ReadinessContainer>
  )
}