import Page from '../../components/Page'
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { NewConversationInput } from '../../components/conversations/NewConversationInput.tsx'
import { NewConversationSuggestions } from '../../components/conversations/NewConversationSuggestions.tsx'
import { useNavigate } from 'react-router-dom'
import { ContentConversation } from '../../@types/conversation.ts'

export function ConversationsHomePage() {
  const navigate = useNavigate()
  const handleConversationCreated = (conversation: ContentConversation) => {
    navigate(`/conversations/${conversation.contentConversationId}`)
  }
  return (
    <Page title='Conversations' sx={{ width: '100%' }}>
      <Stack
        spacing={6}
        sx={{
          paddingTop: 16,
          width: 640,
          margin: '0 auto',
          textAlign: 'center',
        }}
      >
        <Typography variant='h1' fontWeight="lighter" >Get to know your content</Typography>
        <NewConversationInput onConversationCreated={handleConversationCreated}/>
        <NewConversationSuggestions />
      </Stack>
    </Page>
  )
}

