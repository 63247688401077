import { Asset, AssetPermission } from '../../../@types/asset'
import { AssetContentState, AssetContentStateStatus } from '../../../@types/asset-content'
import React from 'react'
import { ReadinessContainer } from '../ReadinessContainer'
import { ViewDocumentSummaryPopover } from './ViewDocumentSummaryPopover'
import { VisibleForPermission } from '../../../guards/VisibleForPermission.tsx'
import { LoadingButton } from '@mui/lab'
import Iconify from '../../Iconify.tsx'
import { Stack } from '@mui/material'
import { useRetryDocumentSummary } from '../../../hooks/useAssetContent.ts'

export function DocumentSummaryReadiness({ asset, state }: { asset: Asset, state: AssetContentState }) {
  const { mutateAsync: rerunSummary, isLoading: summaryInProgress } = useRetryDocumentSummary()
  const handleRetry = () => rerunSummary({ assetId: asset.assetId })
  const isReady = state.status === AssetContentStateStatus.SUCCESS
  const buttonsDisabled = summaryInProgress
  return (
    <ReadinessContainer
      icon='eva:bulb-outline'
      state={state}
      title='Asset Summary'
      subtitle='Summarize assets to help users understand content at a glance. This also aids in helping individuals to search by topics!'
    >
      <Stack direction='row' spacing={1}>
        {isReady && (<ViewDocumentSummaryPopover asset={asset} />)}
        <VisibleForPermission permission={AssetPermission.EDIT_METADATA} level={asset.accessLevel}>
          <LoadingButton
            disabled={buttonsDisabled}
            variant='text'
            size='small'
            startIcon={<Iconify icon='ph:arrow-clockwise' />}
            loading={summaryInProgress}
            loadingPosition='start'
            onClick={handleRetry}
          >
            Rerun
          </LoadingButton>
        </VisibleForPermission>
      </Stack>
    </ReadinessContainer>
  )
}