import { Box, Link, Typography } from '@mui/material'
import { ContentConversation } from '../../@types/conversation.ts'
import { alpha, useTheme } from '@mui/material/styles'
import { Link as RouterLink } from 'react-router-dom'

export function ConversationMenuItem({ conversation }: { conversation: ContentConversation }) {
  const theme = useTheme()
  return (
    <Link
      component={RouterLink}
      to={`/conversations/${conversation.contentConversationId}`}
      style={{ textDecoration: "none" }}
    >
      <Box
        pl={1}
        pr={2}
        py={1}
        ml={-1}
        sx={{
          maxWidth: "100%",
          overflow: "hidden",
          cursor: "pointer",
          borderRadius: .5,
          "&:hover": {
            background: alpha(theme.palette.background.neutral, .075)
          }
        }}
      >
        <Typography
          component="div"
          variant="standard"
          noWrap
          overflow='hidden'
          textOverflow='ellipsis'
          color="text.deemphasized"
        >{conversation.name}</Typography>
      </Box>
    </Link>
  )
}